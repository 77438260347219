import './AuthorsSection.css'

function AuthorsSection({titleLeft, titleRight, descriptionFirstLeft, descriptionSecondLeft, imageLeft, imageRight, isWomanSide, descriptionFirstRight, descriptionSecondRight, brandNameLeft, brandNameRight}) {
    const isMobile = window.innerWidth <= 1199;

    return (
        <section className="authors__section">
            <div className="authors__section-wrapper">
            <div className="aithors__section-left">
                <p className={`authors-text text-title-one ${isWomanSide ? "w" : ""}`}>Автори курсів</p>
                <h2 className={`authors__section-title-left text-title-two ${isWomanSide ? "w" : ""}`}><span className={`${isWomanSide ? "woman-color" : "man-color"}`}>{brandNameLeft}</span> {titleLeft}</h2>
                <h2 className="authors__section-description body-text">{descriptionFirstLeft}</h2>
                <h2 className="authors__section-description-second body-text">{descriptionSecondLeft}</h2>
                <img src={isMobile ? imageRight : imageLeft} alt={`${isMobile? titleRight : titleLeft} фото`} className="authors__section-image-left" />
            </div>
            <div className="aithors__section-right">
                <img src={isMobile ? imageLeft : imageRight} alt={`${isMobile ? titleLeft : titleRight} фото`} className="authors__section-image-right" />
                <div className="authors__section-texts">
                <h2 className="authors__section-title-right text-title-two"><span className={`${isWomanSide ? "man-color" : "woman-color"}`}>{brandNameRight}</span> {titleRight}</h2>
                <h2 className="authors__section-description body-text">{descriptionFirstRight}</h2>
                <h2 className="authors__section-description-second body-text">{descriptionSecondRight}</h2>                </div>

            </div>
            </div>
        </section>
    )
}

export default AuthorsSection