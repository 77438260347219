import { useState } from "react";
import "./CourseListSection.css"
import CourseListItem from "./CourseListItem/CourseListItem";

export default function CourseSection({ courses, isManCourses }) {
    const [openId, setId] = useState(1);
    const [selectedCourse, setSelectedCourse] = useState(courses[1]);

    const handleCourseClick = (id, course) => {
        setId(id)
        setSelectedCourse(course)
    };

    return (
        <section className="course_section_background">
         
            <div className="all_courses_background">
            <div className="all_courses_background-wrapper">

                <div className="courses_title_wrapper">
                    <div className={`course_section_title text-title-one ${isManCourses ? "" : "w"}`}>
                        {isManCourses ? <span className="man-color">Чоловічі </span> : <span className="woman-color">Жіночі </span>}курси
                    </div>
                </div>
                <div className="all_courses_container">
                    {courses.map((course, id) => {
                        return (
                            <CourseListItem onClick={() => id === openId ? setId(null) : handleCourseClick(id, course) } isOpen={id===openId} course={course} key={id} isManCourses={isManCourses} />
                        )
                    })}
                </div>
                </div>
            </div>
            {selectedCourse && (
                <div className="course_image_background">
                    <img className="course_image" src={selectedCourse.imageSrc} alt={selectedCourse.title} />
                </div>
            )}
        </section>
    );
}