import "./ForWhoSection.css";

function ForWhoSection({
  isManSide,
  isCoursePage,
  courseName,
  forWhoArray,
}) {
  return (
    <section className="for__who-section">
      <div className="for__who-section-wrapper">
        <h2 className={`for__who-section-title text-title-one ${isManSide ? "" : "w"}`}>
          Кому{" "}
          {isCoursePage ? (
            <span className={isManSide ? "man-color" : "woman-color"}>
              {courseName}
            </span>
          ) : (
            <span className={isManSide ? "man-color" : "woman-color"}>це</span>
          )}{" "}
          підійде
        </h2>
        <div className="for__who-section-items">
          {forWhoArray.map((item, idx) => {
            return (
              <div key={idx} className="for__who-section-item">
                <img src={item.image} alt={item.description} className="for__who-section-item-icon" />
                <div className="for__who-section-item-texts">
                  <div className="for__who-section-item-title">
                  <img src={item.image} alt={item.description} className="for__who-section-item-icon-mobile" />
                    <h2 className={`text-title-two ${isManSide ? "" : "w"}`}>{item.title}</h2>
                  </div>
                  <div className="for__who-section-item-description body-text">
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ForWhoSection;
