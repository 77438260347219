import "./ModalAge.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAdult } from "../../redux/ModalSlice/ModalSlice";

const ModalAge = () => {
  const dispatch = useDispatch();
  const isAdult = useSelector((state) => state.modal.isAdult);


  const handleYes = () => {
    dispatch(setIsAdult(true));
  };

  const handleNo = () => {
    dispatch(setIsAdult(false));
    if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      window.location.href =
        "https://www.google.com/search?q=leon13+tinakross&rlz=1C5GCCM_en&oq=leon13&gs_lcrp=EgZjaHJvbWUqCAgBEEUYJxg7MgYIABBFGDwyCAgBEEUYJxg7Mg4IAhBFGBQYORiHAhiABDIGCAMQRRg7MggIBBBFGCcYOzIGCAUQRRg7MgYIBhBFGDwyBggHEEUYPNIBCDI3NjNqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8";
    }
  };

  return (
    <div className="modal__age">
        <div className="modal__age-body">
          <h2 className="modal__age-title text-title-one">Вам є <span style={{color: "red"}}>18 років</span>?</h2>
          <h3 className="modal__age-description text-title-two">Контент на сайті призначений для осіб, яким вже виповнилось 18 років.</h3>
          <div className="modal__age-buttons">
          <button className="modal__age-button text-title-one" onClick={handleYes}>Мені виповнилось 18</button>
          <button className="modal__age-button text-title-one" onClick={handleNo}>Я молодше 18</button>
          </div>
          <p className="modal__age-description-confirm body-text">Натискаючи на кнопку “Мені виповнилось 18” Ви підтверджуєте,що вам вже виповнилось повних 18 років.</p>
        </div>
    </div>
  );
};

export default ModalAge;
