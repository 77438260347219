import React from "react";
import LazyLoad from "react-lazy-load";
import "./SliderCard.css";

function SliderCard({ src, data, href, isManSide }) {
  return (
    <div className="slider__card">
      <LazyLoad offset={300} className="lazy-load-container">
        <div>
          <img src={src} alt={`на фото ${data}`} className="graduate__image" />
          <div className="graduate__data">
            <p className="graduate__text body-text">{data}</p>
            <a target={"_blank"} rel={"noreferrer"} href={href} className={`graduate__link ${isManSide? "" : "w"} body-text`}>Зв’язатися</a>
          </div>
        </div>
      </LazyLoad>
    </div>
  );
}

export default SliderCard;

