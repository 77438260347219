import forWhoIconOne from "../assets/icons/for-who-icon-one.png";
import forWhoIcontwo from "../assets/icons/for-who-icon-two.png";
import forWhoIconThree from "../assets/icons/for-who-icon-three.png";
import forWhoIconFour from "../assets/icons/for-who-icon-four.png";
import forWhoIconFive from "../assets/icons/for-who-icon-five.png";
import forWhoIconSix from "../assets/icons/for-who-icon-six.png";
import coursePhotoOneWoman from "../assets/images/Glubokaya-dusha-img.webp";
import coursePhotoTwoWoman from "../assets/images/akvapark-girls.webp";
import coursePhotoThreeWoman from "../assets/images/Tilo-kinchai-img.webp";
import coursePhotoFourWoman from "../assets/images/pozy-18-img.webp";
import coursePhotoFiveWoman from "../assets/images/anal-do-skvirta-img.webp";
import coursePhotoSixWoman from "../assets/images/lingam-img.webp";
import coverPozy from '../assets/images/course-cover-pozy.webp'
import coverAnal from '../assets/images/course-cover-anal.webp'
import coverKiss from '../assets/images/course-cover-kisses.webp'
import coverDeepSoul from '../assets/images/cours-cover-deep-soul.webp'
import coverAquaG from '../assets/images/cours-cover-aqua-g.webp'
import coverTiloKinchay from '../assets/images/cours-cover-tilo-kinchay.webp'
import coverLingam from '../assets/images/cours-cover-lingam.webp'
import coursePhotoEightMan from "../assets/images/kisses-img.webp";


export const forWhoArrayWoman = [
  {
    title: "Тій, що тільки починає",
    description:
      "Потенціал, який в тобі закладений, пора розкрити на повну. З нуля у сексі, ти станеш твердою десяткою.",
    image: forWhoIconOne,
  },
  {
    title: "Досвідченій жінці",
    description:
      "Ви точно знаєте цінність знань. Тут ви згадаєте забуті та знайдете нові тематики для розвитку своєї жіночої енергії.",
    image: forWhoIcontwo,
  },
  {
    title: "Затятій акулі сексу",
    description:
      "Рокова жінка? Ні. Жінка від якої пахне сексом — ось якого рівня ти досягнеш поглибивши свої знання.",
    image: forWhoIconThree,
  },
  {
    title: "Не лише жінкам?",
    description:
      "Ніяких гендерних обмежень! Бі та геї — ласкаво просимо)",
    image: forWhoIconFour,
  },
  {
    title: "Тим, хто хоче трахати та заробляти",
    description:
      "Секс — це те, за що готові заплатити. Прийшов час знайти роботу, на якій тобі платитимуть за твою насолоду.",
    image: forWhoIconFive,
  },
  {
    title: "Секс експерту",
    description:
      "Ну нарешті — якісне навчання. Теорія+практика і все на одній платформі — це скарб, для підняття своєї кваліфікації.",
    image: forWhoIconSix,
  },
];

export const deepArr = {
  forWho: [
  {
    title: "Дівчині, яка хоче сосати як в порно",
    description:
      "Покроковий гайд технік з індустрії фільмів для дорослих, які ти не знайдеш в YouTube",
    image: forWhoIconOne,
  },
  {
    title: "Хто хоче брати глибше",
    description:
      "Як позбутися рвотного рефлексу, чому я не можу взяти глибше, як змусити його трястись від насолоди — усі відповіді вже на курсі",
    image: forWhoIcontwo,
  },
  {
    title: "Тій, яка шукає методи як сосати та зароблять",
    description:
      'Спікери із секс індустрії розкриють усі секрети та лайфхаки порно мінету',
    image: forWhoIconThree,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Курс підійде для професіоналок своєї справи: тут ви прокачаєте свої і отримаєте нові скіли профільного спеціаліста",
    image: forWhoIconFour,
  },
  {
    title: "Дівчині або хлопцю",
    description:
      "На курсі чимало і хлоп’ят, а також інформації від спікерів геїв, які знають толк в гарному мінеті",
    image: forWhoIconFive,
  },
  {
    title: "Жінці, яка  шукає виходу для своєї сексуальної енергії",
    description:
      "Ми вчимо не просто сосати член. Тут ти зламаєш усі кордони у своїй голові і поставиш рекорди у своїй глотці",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавжди та постійне оновлення уроків, без додаткових доплат",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Офлайн з Тіною Кросс",
      description:
        "Для випускниць курсу є можливість зустрітись офлайн особисто з засновницею, для ще глибшого вивчення не лише курсу, а й себе самої",
    },
    {
      number: 5,
      title: "Сосатимеш, як порно акторка чи... актор?",
      description:
        "Уроки від спікера гея — бонус плюс для пізнання світу задоволення чоловіків.",
    },
    {
      number: 6,
      title: "Створення твоєї анкети випускниці",
      description:
        "Після курсу ти зможеш потрапити в антети випускниць і отримати звання глибокодушної профі",
    },
  ],

};
export const aquaGArr = {
  forWho: [
  {
    title: "Дівчині, яка хоче пізнати партнера по новому",
      description:
        "Своїми руками ти покажеш йому, що таке кайф у кожній клітинці тіла",
    image: forWhoIconOne,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Курс підійде для професійних майстрів масажу: тут ви прокачаєте свої і отримаєте нові скіли профільного спеціаліста",
    image: forWhoIcontwo,
  },
  {
    title: "Дівчині або хлопцю",
    description:
      'Задоволення чоловіку може доставити не лише жінка, а й чоловік)',
    image: forWhoIconThree,
  },
  {
    title: "Жінці, яка  шукає виходу для своєї сексуальної енергії",
    description:
      "Розкрий свою сексуальність крізь тіло чоловіка",
    image: forWhoIconFour,
  },
  {
    title: "Квітці, яка тільки розпустилася",
    description:
      "Цей курс — база, тому якщо шукаєш з чого б почати — ти знайшла правильне місце",
    image: forWhoIconFive,
  },
  {
    title: "Тій, що хоче нових відчуттів",
    description:
      "Змусь його відчути так, як він ніколи не відчував. Техніки, які освіжають навіть найсухіші стосунки",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавжди та постійне оновлення уроків, без додаткових доплат",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Покрокове та наочне відео усіх технік",
      description:
        "Після курсу твоя шпаргалка буде з тобою за будь-яких умов та не залежно від розташування",
    },
    {
      number: 5,
      title: "Повний список девайсів",
      description:
        "Розкажемо та покажемо де взяти все, що тобі треба для навчання і після нього",
    },
    {
      number: 6,
      title: "Офлайн з Тіною Кросс",
      description:
        "Для випускниць курсу є можливість зустрітись офлайн особисто з засновницею, для ще глибшого вивчення не лише курсу, а й себе самої",
    },
  ],

};
export const tiloKArr = {
  forWho: [
  {
    title: "Дівчині, яка хоче пізнати оргазм",
    description:
      "Навчи своє тіло кінчати та сквіртувати, як сама, так і з партнером",
    image: forWhoIconOne,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Сексологи та сексологині, welcome to the club)",
    image: forWhoIcontwo,
  },
  {
    title: "Жінкам, яким життєво необхідно це почути",
    description:
      'Тут уся твоя біль перетвориться в силу — ти не одна',
    image: forWhoIconThree,
  },
  {
    title: "Жінці, яка  шукає виходу для своєї сексуальної енергії",
    description:
      "Розкрий нарешті свою сексуальність — випускай своїх демонів",
    image: forWhoIconFour,
  },
  {
    title: "Квітці, яка тільки розпустилася",
    description:
      "Цей курс — база, тому якщо шукаєш з чого б почати — ти знайшла правильне місце",
    image: forWhoIconFive,
  },
  {
    title: "Тій, що задовбалась імітувати",
    description:
      "Чому ти це робиш і як почати кінчати по справжньому — усі відповіді вже тут, lets go",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавжди та постійне оновлення уроків, без додаткових доплат",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 3,
      title: "Доступ до закритого ЖІНОЧОГО ПРОСТОРУ",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Покрокове та наочне відео усіх технік",
      description:
        "Після курсу твоя шпаргалка буде з тобою за будь-яких умов та не залежно від розташування",
    },
    {
      number: 5,
      title: "Оргазм",
      description:
        "Ти навчиш своє тіло кнічати і зможеш навіть сама доводити себе до піків та сквірту",
    },
    {
      number: 6,
      title: "Офлайн з Тіною Кросс",
      description:
        "Для випускниць курсу є можливість зустрітись офлайн особисто з засновницею, для ще глибшого вивчення не лише курсу, а й себе самої",
    },
  ],

};
export const lingamArr = {
  forWho: [
  {
    title: "Дівчині, яка хоче пізнати оргазм чоловіка з середини",
    description:
      "На курсі ти відкриєш усі таємниці чоловічого оргазму, та як вивести його на вищий рівень",
    image: forWhoIconOne,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Індустрія еро масажів просто потребує спеціалістів",
    image: forWhoIcontwo,
  },
  {
    title: "Жінкам і чоловікам?",
    description:
      'Геї та бішечки — ласкаво просимо. На курсі експерт медик і гей в одному флаконі — готуйте записнички',
    image: forWhoIconThree,
  },
  {
    title: "Жінці, яка  шукає виходу для своєї сексуальної енергії",
    description:
      "Навчись дихати крізь нього — розкриємо усю тебе через твого чоловіка",
    image: forWhoIconFour,
  },
  {
    title: "Тій, що хоче додати фарб у сірий секс",
    description:
      "Навіть після років разом — ти його здивуєш",
    image: forWhoIconFive,
  },
  {
    title: "Дівчині, яка знає — чоловіче здоров’я треба підтримувати",
    description:
      "Ми розкриваємо усі методи та медичні рекомендації масажу простати, щоб його стояк радував тебе до глибокої старості",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "Якщо виникнуть питання, завжди є можливість поставити їх, як щодо курсу, так і особисті",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Зв'язок особисто з засновниками 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 6,
      title: "Лайфхаки з індустрії фільмів для дорослих",
      description:
        "Чим користуються актори порно і які техніки найефективніші для кам'яного стояка",
    },
  ],

};

export const womanCourses = [
  {
    title: "Глибока Душа",
    describtion: "Новий формат твого мінету. Слюнявий — це не просто техніки і практики, як довести чоловіка до оргазму. Ні! Тут ти навчишся пізнавати себе через чоловіка. Адже сосати добре може лише та, кому по справжньому це подобається. Усе починається з голови, а тільки потім продовжується у глотці. Тому, якщо ти готова почати кайфувати від себе та підкріплювати цей ефект його стогоном задоволення — тоді welcome to the club.",
    price: "200$",
    imageSrc: coursePhotoOneWoman,
    url: "/woman-side/deep-soul",
    willLernArr: ["Відповідь на запитання «Чому ти не любиш сосати»", "Як позбутися рвотного рефлексу не використовуючи спреї", "Як “дихати його членом”: найкращі техніки від Тіни Кросс", "Що відчуває чоловік: поради від спікерів геїв", "Як отримати кам’яний стояк", "Як влаштувати йому порно шоу",],
    urlCover: coverDeepSoul,
  },
  {
    title: "Аквапарк Girls",
    describtion: "Дізнайся на що спроможні твої ручки. Підкорити чоловіка повністю — ось якою силою ви  оволодієте. Техніки, які  не знайдеш у звичайних “масажках” - створені головним майстром еро технік в Україні: Leon13. Тільки попереджаємо одразу — у нього може з’явитись залежність від твоїх масажів. Якщо готова — тоді гоу)",
    price: "180$",
    imageSrc: coursePhotoTwoWoman,
    url: "/woman-side/aquapark-girls",
    willLernArr: ["Як бути сексуальною, коли робиш йому масаж: топ вигідних позицій", "Ази масажу лінгама, якого в нього ще ніколи не було", "Як тренуватися вдома навіть якщо хлопця немає поруч", "Як нативно змусити його СТОГНАТИ", "Покроково - техніки еро масажу усього тіла", "Повний гайд по девайсам для масажу: що до чого, як, куди та скільки",],
    urlCover: coverAquaG,
  },
  {
    title: "Тіло Кінчай",
    describtion:
      "Тут ти навчиш СВОЄ ТІЛО - КІНЧАТИ! Цей курс - інструкція до ТЕБЕ, з якою ти розкриєш усі свої потаємні можливості та бажання",
    price: "350$",
    imageSrc: coursePhotoThreeWoman,
    url: "/woman-side/tilo-kinchay",
    willLernArr: ["Де живе сексуальність і як її з себе дістати", "Чому ти не бачиш себе справжню", "Відповідь від сексолога: як навчитись кінчати", "Що нормально мати проблеми, але не нормально забити на них х¥й", "Сквірт - як це відбувається і що робити тобі, щоб нарешті його досягти", "Повну історію “від Христини до Тіни Кросс”",],
    urlCover: coverTiloKinchay,
  },
  {
    title: "Пози 18+",
    describtion: "Такого формату сексу в тебе ще не було! Пози, які удосконалені та протестовані десятками пар. Такій деталізації позаздрить навіть Камасутра, адже цей курс — інструкція оновлення вашого інтиму до версії РRO. Навіть звичні пози ми перетворимо на витвори оргазмового мистецтва. Попереджаємо одразу — сусіди вас зненавидять і їх нічого не врятує від перевищення рівня децибелів.",
    price: "130$",
    imageSrc: coursePhotoFourWoman,
    url: "/woman-side/pozy18",
    willLernArr: ["Про нові формати вже звичних поз", "Як вакуум впливає на ваш секс: топ техніки від Тіни Кросс", "Як професійно змінювати пози не виймаючи член", "Лайфхаки які доведуть до піку вас обох", "Як зробити масаж члена мʼязами вагіни", "Про “тверк на члені” для нового рівня ваших відчуттів покроково",],
    urlCover: coverPozy,
  },
  {
    title: "Анал до сквірта",
    describtion: "Відкрий новий вхід у світ насолоди. Чому цей курс у розділі для пар? Бо сквіртовий анал вимагає знань від двох. Тому ми зібрали найефективнішу підготовку до АНАЛЬНОГО СЕКСУ для дівчини і хлопця. Дівчата, готуй не лише свою жопу, а й мозок — тут ми пропрацює усі тригери, розтиснемо булочки та душу. А ви пацики готуйте член та мізки — тут потрібні вони обидва. READY?",
    price: "130$",
    imageSrc: coursePhotoFiveWoman,
    url: "/woman-side/anal-do-skvirta",
    willLernArr: ["Чому і як потрібно готуватися до АС вам обом", "Як пропрацювати психологічні моменти перед аналом", "Фішки та лайфхаки від порно акторів, щоб оргазмувати по новому", "Як помножити її кайф: подвійне проникнення", "Мінет аналом — прокачайся до рівня профі", "Правила та способи використання допоміжних девайсів",],
    urlCover: coverAnal,
  },
  {
    title: "Лінгам-Простата",
    describtion:
      "Він кінчить за секунди! Ти станеш тією, хто відведе чоловіка у світ насолоди і підкорить тіло, діставши до душі. Тут ти вчитимешся справжньому мистецтву заволодіння самим єством лише з допомогою своїх рук. Якщо ти готова стати його залежністю, то погнали.",
      price: "160$",
    imageSrc: coursePhotoSixWoman,
    url: "/woman-side/lingam-prostata",
    willLernArr: ["Чому масаж лінгама - це не про ДРОЧКУ", "10+ технік масажу, які виведуть його відчуття на новий рівень", "Як повернути чутливість його члену", "У чому користь масажу простати для здоров’я", "Як пропрацювати психологічні блоки у чоловіків", "Що саме він відчуває та як зробити так, щоб він просив тебе про це знову і знову",],
    urlCover: coverLingam,
  },
  {
    title: "Цілуй",
    describtion:
      "Поцілунок — це те, з чого усе починається. І в кожного є та сама крінж історія поцілунку, яку твій мозок намагається забути. “Та що там його цілуватися” - фатальна помилка. Поцілунок — інтимніше навіть сексу. Саме він може або подарувати тобі найкращі стосунки твого життя або зруйнувати все ще на самому початку. Час вивести свої цілувальні здібрості на рівень ПРОФІ. Починай вивчати вже зараз",
    price: "50$",
    imageSrc: coursePhotoEightMan,
    url: "/woman-side/kiss",
    willLernArr: ["Як почати цілуватись так, щоб продовжувати вечір вже у ліжку", "Про важливість поцілунку: це те з чого усе і починається", "Як точно не треба: Вивчай, щоб не стати героєм історії про “поцілунок шарпея”", "Усе про поцілунок в трьох, для справжніх поціновувачів", "Поцілунки з льодом та смаком -  те, від чого знесе дах обом", "Які моменти ти пропускаєш, що ламає увесь кайф поцілунку",],
    urlCover: coverKiss,
  },
];
