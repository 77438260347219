import "./CourseListItem.css";
import { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import plusIcon from '../../../assets/icons/CoursePlus.png'

function CourseListItem({ course, onClick, isOpen, isManCourses }) {
  const itemRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      const height = itemRef.current.scrollHeight;
      itemRef.current.style.height = `${height}px`;
      // Принудительный пересчет стилей
      void itemRef.current.offsetHeight;
    } else {
      itemRef.current.style.height = "0px";
    }
  }, [isOpen, location]);


  return (
    <div className="accordion-item">
      <div onClick={onClick} className="accordion-header-wrapper">
        <button className={`accordion-header text-title-two ${isManCourses ? "" : "w"}`}>
          {course.title}
        </button>
        <img
          src={plusIcon}
          alt="зображення плюсика. при натисканні відкриється компонент відповіді"
          className={`plus__icon ${isOpen ? "active" : ""}`}
        />
      </div>
      <div className="accordion-collapse" ref={itemRef}>
        <p onClick={onClick} className="accordion-body body-text">
          {course.describtion}
        </p>
        <div className="price_learnMore">
          <p className="course_price body-text">Ціна: {course.price}</p>
          <Link to={course.url} className={`learn_more_button body-text`}>
            {" "}
            <p className={`${isManCourses ? "man-color" : "woman-color"}`}>
              Дізнатися більше &#61;&#61;&gt;
            </p>
          </Link>
        </div>

        <div className="accordion__mobile-wrapper">
          <img alt={course.title} src={course.imageSrc} className="accordion__mobile-image" />
          <Link className={`accordion__mobile-button ${isManCourses? "man" : "woman"} body-text`} to={course.url}>
            Дізнатися більше
          </Link>
        </div>
      </div>

     
    </div>
  );
}

export default CourseListItem;
