import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { MANSIDE_ROUTE, WOMANSIDE_ROUTE } from "../../utils/constants";
import "./MainPage.css";
// import leonMainVideoPC from "../../assets/videos/video-leon-main-pc.webm";
// import tinaMainVideoPC from "../../assets/videos/video-tina-main-pc.webm";
import { useState } from "react";
import coverManPc from '../../assets/images/cover-main-man-pc.webp'
import coverWomanPc from '../../assets/images/cover-main-woman-pc.webp'

function MainPage() {
  const [isManButtonHovered, setIsManButtonHovered] = useState(false);
  const [isWomanButtonHovered, setIsWomanButtonHovered] = useState(false);
  const handleManButtonHover = () => {
    setIsManButtonHovered(true);
    setIsWomanButtonHovered(false);
  };
  const isMobile = window.innerWidth <= 1199;

  const handleWomanButtonHover = () => {
    setIsWomanButtonHovered(true);
    setIsManButtonHovered(false);
  };

  return (
    <div className="main__page">
      <div className="main__video-section">
        <div
          className={`main__video-leon-wrapper ${
            isManButtonHovered ? "hovered" : ""
          }`}
        >
          {isMobile ? (
            <img className="react-player-mobile" src={coverManPc} alt="-" />
          ) : (
            <img src={coverManPc} alt="Фото Леона з Тіною" className="react-player" />
            // <ReactPlayer
            //   url={leonMainVideoPC}
            //   className="react-player"
            //   playing={isManButtonHovered ? true : false}
            //   loop
            //   muted
            //   width="100%"
            //   height="100%"
            // />
          )}

          <Link to={MANSIDE_ROUTE}>
            <button
              onMouseEnter={handleManButtonHover}
              className={`side__button-man ${
                isWomanButtonHovered ? "hovered" : ""
              } logo-text-man`}
            >
              <div className="side__button-texts">
                <p
                  className={`side__button-title-man ${
                    isManButtonHovered ? "hovered" : ""
                  }`}
                >
                  <span className="man-color">Man</span> Side
                </p>
                <p
                  className={`side__button-description  ${
                    isManButtonHovered ? "hovered" : ""
                  } body-text`}
                >
                  Не ти шукаєш жінку, а вона шукає тебе
                </p>
              </div>
            </button>
          </Link>
        </div>
        <div
          className={`main__video-tina-wrapper ${
            isWomanButtonHovered ? "hovered" : ""
          }`}
        >
          {isMobile ? (
            <img className="react-player-mobile" src={coverWomanPc} alt="-" />
          ) : (
            <img src={coverWomanPc} alt="Фото Тіни Кросс" className="react-player" />
            // <ReactPlayer
            //   url={tinaMainVideoPC}
            //   className="react-player"
            //   playing={isWomanButtonHovered ? true : false}
            //   loop
            //   muted
            //   width="100%"
            //   height="100%"
            // />
          )}
          <Link to={WOMANSIDE_ROUTE}>
            <button
              onMouseEnter={handleWomanButtonHover}
              className={`side__button-woman ${
                isManButtonHovered ? "hovered" : ""
              } logo-text-woman`}
            >
              <div className="side__button-texts">
                <p
                  className={`side__button-title-woman ${
                    isWomanButtonHovered ? "hovered" : ""
                  }`}
                >
                  <span className="woman-color">Woman</span> Side
                </p>
                <p
                  className={`side__button-description  ${
                    isWomanButtonHovered ? "hovered" : ""
                  } body-text`}
                >
                  Час випустити своїх демонів
                </p>
              </div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
