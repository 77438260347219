import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  isFormSended: false,
  isAdult: JSON.parse(localStorage.getItem('isAdult'))
};

const ModalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
   setIsActive: (state, action) => {
    state.isActive = true
   },
   setIsClose: (state, action) => {
    state.isActive = false
   },
   setIsFormSended: (state) => {
    state.isFormSended = true;
  },
  setClearIsFormSended: (state) => {
    state.isFormSended = false;
  },
  setIsAdult: (state, action) => {
    state.isAdult = action.payload;
    localStorage.setItem('isAdult', JSON.stringify(action.payload));
  },
  resetIsAdult: (state) => {
    state.isAdult = null;
    localStorage.removeItem('isAdult');
  },
  },
});

export const {
  setIsActive,
  setIsClose,
  setIsFormSended,
  setClearIsFormSended,
  setIsAdult,
  resetIsAdult,
} = ModalSlice.actions;
export default ModalSlice.reducer;
