import React, { useEffect } from "react";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import './AppRouter.css'
import FooterContacts from "../FooterContacts/FooterContacts";
import Footer from "../Footer/Footer";
import Modal from "../Modal/Modal";
import Burger from "../Burger/Burger";
import { useSelector } from "react-redux";
import ModalAge from "../ModalAge/ModalAge";


function AppRouter() {
  const { pathname } = useLocation()
  const isAdult = useSelector((state) => state.modal.isAdult);

  useEffect(()=> {
    window.scrollTo(0,0);
  }, [pathname, isAdult])

  if (!isAdult) {
    return <ModalAge />;
  }

  return (
    <div className="global__container">
      <Header />
      <main className="main">
        <Outlet />
      </main>
      <FooterContacts />
      <Footer />
      <Modal />
      <Burger />
    </div>
  );
}

export default AppRouter;
