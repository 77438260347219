import CourseSection from "../../components/CourseListSection/CourseListSection"
import { manCourses } from "../../utils/arraysMan"
import { womanCourses } from "../../utils/arraysWoman"

function AllCoursesPage() {
    return (
        <div className="all__courses-page">
            <CourseSection courses={manCourses} isManCourses={true} />
            <CourseSection courses={womanCourses} isManCourses={false} />
        </div>
    )
}

export default AllCoursesPage