function FooterContactsButton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
    >
      <circle cx="125" cy="125" r="124.5" stroke="url(#paint0_linear_76_117)" />
      <defs>
        <linearGradient
          id="paint0_linear_76_117"
          x1="0"
          y1="125"
          x2="250"
          y2="125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9000" />
          <stop offset="1" stopColor="#E40AC1" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FooterContactsButton;
