import './Footer.css'

function Footer() {
        return (
        <footer className="footer">
            <div className="footer__wrapper">
                <p className="rights body-text">Copyright 2024. All rights reserved</p>
                <a className='footer__phone body-text' href="https://t.me/hub1317_admin">Зв’язатися з менеджером</a>
            </div>
        </footer>
    )
}

export default Footer