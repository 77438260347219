import "./SliderSection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import SliderCard from "./SliderCard/SliderCard";

function SliderSection({ sliderArr, isManSide }) {
  const isMobile = window.innerWidth <= 1199;

  return (
    <section className="slider__section">
      <h2 className={`slider__section-title text-title-one ${isManSide ? "" : "w"}`}>
        Анкети випускників
      </h2>
      <div className="slider__wrapper">
        <Swiper
          slidesPerView={isMobile ? 1 : 6}
          loop={true}
          navigation={isMobile ? true : true}
          modules={[Navigation]}
          className="mySwiper"
        >
          {sliderArr.map((slide, idx) => (
            <SwiperSlide key={idx}>
              <SliderCard
                src={slide.src}
                href={slide.href}
                data={slide.data}
                isManSide={isManSide}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      <a rel={"noreferrer"} href="https://t.me/onlyhotboys18" target={"_blank"} className={`moge__graduate-link ${isManSide ? "" : "w"} body-text`}>Всі анкети</a>
      </div>
    </section>
  );
}

export default SliderSection;

