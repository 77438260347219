import ForWhoSection from '../../components/ForWhoSection/ForWhoSection'
import CourseAbout from './CourseAbout/CourseAbout'
import CourseBenefits from './CourseBenefits/CourseBenefits'
import './CoursePage.css'
import CoursePageCover from './CoursePageCover/CoursePageCover'


function CoursePage({urlCover, aboutUrl, aboutTextFirst, aboutTextSecond, aboutTitle, courseName, isManSide, courseArr, willLernArr}) {
    return (
        <div className="course__page">
            <CoursePageCover urlCover={urlCover}/>
            <CourseAbout willLernArr={willLernArr} aboutUrl={aboutUrl} isManSide={isManSide} aboutTextFirst={aboutTextFirst} aboutTextSecond={aboutTextSecond} aboutTitle={aboutTitle} />
            <CourseBenefits isManSide={isManSide} benefiteCourseArr={courseArr.benefiteCourseArr} />
            <ForWhoSection isCoursePage={true} courseName={courseName} isManSide={isManSide} forWhoArray={courseArr.forWho} />
        </div>
    )
}

export default CoursePage