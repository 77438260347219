import "./SideCover.css";

function SideCover({ image, title, isWomanSide, description, brandText }) {
  return (
    <div className="side__cover">
      <img
        src={image}
        alt={`Чоловічий простір. Слоган : ${title}`}
        className="side__cover-image"
      />
      <div className="side__cover-texts">
        <h1 className={`side__cover-title text-title-one ${isWomanSide ? "w" : ""}`}>{title}</h1>
        <h2 className="side__cover-description text-title-two">
        {description}
        <span className={`${isWomanSide ? "woman-color" : "man-color"}`}>{brandText}</span>
        </h2>
      </div>
      <div className="side__cover-fade"></div>
    </div>
  );
}

export default SideCover;
