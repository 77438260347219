// import { useEffect, useRef } from "react";
// import ReactPlayer from "react-player";
import "./CoursePageCover.css";


function CoursePageCover({ urlCover }) {
  // const playerRef = useRef(null);

  // useEffect(() => {
  //   if (playerRef.current) {
  //     const videoElement = playerRef.current.getInternalPlayer();
  //     if (videoElement) {
  //       videoElement.setAttribute("playsinline", "");
  //     }
  //   }
  // }, []);
  return (
    <div className="course__cover">
      <div className="course__cover-player">
        <img src={urlCover} alt="фото курсу" className="course__cover-image" />
        {/* <ReactPlayer
          ref={playerRef}
          url={url}
          playing
          muted
          loop
          width={"100%"}
          height={"100%"}
        /> */}
      </div>
      <div className="side__cover-fade"></div>
    </div>
  );
}

export default CoursePageCover;
