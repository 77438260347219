import Benefite from './Benefite/Benefite'
import './CourseBenefits.css'

function CourseBenefits({isManSide, benefiteCourseArr}) {
    return (
        <section className="course__benefits">
            <h2 className={`course__benefits-title text-title-one ${isManSide ? "" : "w"}`}>
            Плюшки після <span className={isManSide ? "man-color" : "woman-color"}>Курсу</span>
            </h2>
            <div className="course__benefits-wrapper">
                {benefiteCourseArr.map((benefite, idx) => {
                    return (
                        <Benefite isManSide={isManSide} key={idx} benefiteNumber={benefite.number} benefiteTitle={benefite.title} benefiteDescription={benefite.description}/>
                    )
                })}
            </div>
        </section>
    )
}

export default CourseBenefits