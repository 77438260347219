import forWhoIconOne from "../assets/icons/for-who-icon-one.png";
import forWhoIcontwo from "../assets/icons/for-who-icon-two.png";
import forWhoIconThree from "../assets/icons/for-who-icon-three.png";
import forWhoIconFour from "../assets/icons/for-who-icon-four.png";
import forWhoIconFive from "../assets/icons/for-who-icon-five.png";
import forWhoIconSix from "../assets/icons/for-who-icon-six.png";
import coursePhotoOneMan from "../assets/images/potenceal-chlena-img.webp";
import coursePhotoTwoMan from "../assets/images/Akvapark-ekspress-img.webp";
import coursePhotoFiveMan from "../assets/images/Dominant-img.webp";
import coursePhotoFourMan from "../assets/images/akvapark-pro-max-vip-img.webp";
import coursePhotoSixMan from "../assets/images/kuni-img.webp";
import coursePhotoThreeMan from "../assets/images/anal-do-skvirta-img.webp";
import coursePhotoSevenMan from "../assets/images/pozy-18-img.webp";
import coursePhotoEightMan from "../assets/images/kisses-img.webp";
import coverPotencial from '../assets/images/course-cover-potencial.webp'
import coverAquaEx from '../assets/images/course-cover-aqua-express.webp'
import coverKyni from '../assets/images/course-cover-kyni.webp'
import coverAquaPro from '../assets/images/course-cover-aqua-pro.webp'
import coverPozy from '../assets/images/course-cover-pozy.webp'
import coverAnal from '../assets/images/course-cover-anal.webp'
import coverKiss from '../assets/images/course-cover-kisses.webp'
import coverDominant from '../assets/images/course-cover-dominant.webp'

export const forWhoArray = [
  
    {
      title: "Новачку, який хоче стати асом",
      description:
        "Потенціал, який у тобі є, пора розкрити на повну. З нуля у сексі, ти станеш твердою десяткою.",
      image: forWhoIconOne,
    },
    {
      title: "Бувалому вовку",
      description:
        "Ви точно знаєте цінність знань. Тут ви згадаєте забуті та знайдете нові тематики для розвитку своєї чоловічої сили.",
      image: forWhoIcontwo,
    },
    {
      title: "Затятій акулі сексу",
      description:
        "Стань справжнім профі: тим, чиє ім’я вона кричатиме уві сні згадуючи про тебе",
      image: forWhoIconThree,
    },
    {
      title: "Не лише чоловікам?",
      description:
        "Хто сказав, що трахають лише чоловіки? Ніяких гендерних обмежень! Бі та лесбі girls — ласкаво просимо)",
      image: forWhoIconFour,
    },
    {
      title: "Тим, хто хоче трахати та заробляти",
      description:
        "Секс — це те, за що готові заплатити. Прийшов час знати роботу на якій тобі платитимуть за твою насолоду.",
      image: forWhoIconFive,
    },
    {
      title: "Секс експерту",
      description:
        "Ну нарешті — якісне навчання. Теорія+практика і все на одній платформі — це скарб, для підняття своєї кваліфікації",
      image: forWhoIconSix,
    }
  ]

export const PotencialArr = {
  forWho: [
  {
    title: "Тому, хто хоче вивести свій секс на вищий рівень",
    description:
      "Тут ви знайдете інформацію, як прокачувати своє лібідо та як розкрити потенціал свого прутня на усі 120%",
    image: forWhoIconOne,
  },
  {
    title: "Хто шукає методи як трахати та заробляти",
    description:
      "Лайфхаки з порно та олдскульні методи для залізного стояка",
    image: forWhoIcontwo,
  },
  {
    title: "Кому треба взяти під контроль свої оргазми",
    description:
      'Відповімо вам на питання "чому я кінчаю надто швидко" та "чому я не можу кінчити", а також "як кінчати разом з дівчиною"',
    image: forWhoIconThree,
  },
  {
    title: "Мену в якого складнощі з ерекцією",
    description:
      "Чому твій друг не достатньо стійкий? Розберемо все по поличках і зламаємо це безерекційне коло",
    image: forWhoIconFour,
  },
  {
    title: "Чоловіку який хоче прокачати працездатність члена",
    description:
      "Виконуючи вправи ти досягнеш рівня з яким стояк буде кам’яним навіть в глибокій стaрості",
    image: forWhoIconFive,
  },
  {
    title: "Акулам, які хочуть секс у трьох",
    description:
      "В цьому розділі уся інфа про фішки сексу у трьох з особистого досвіду Леона13",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "Якщо виникнуть питання, завжди є можливість поставити їх, як щодо курсу, так і особисті",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Зв'язок особисто з Сергієм 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 6,
      title: "Лайфхаки з індустрії фільмів для дорослих",
      description:
        "Чим користуються актори порно і які техніки найефективніші для кам'яного стояка",
    },
  ],

};
export const AquaExArr = {
  forWho: [
  {
    title: "Тому, хто хоче вивести свій секс на вищий рівень",
    description:
      "Тут ви знайдете покроковий гайд, як доветси свою дівчину до оргазму/свірту/ейфорії",
    image: forWhoIconOne,
  },
  {
    title: "Хто шукає методи як трахати та заробляти",
    description:
      "Створення атмосфери, як у якісному порно фільмі",
    image: forWhoIcontwo,
  },
  {
    title: "Акулам, які хочуть не тонути, а плавати",
    description:
      'Твори з її йоні справжнє море, розігріваючи її ще до масажу',
    image: forWhoIconThree,
  },
  {
    title: "Усім хто хоче навчитись тому самому “АКВАПАРКУ”",
    description:
      "В курсі ви знайдете уроки і від Сергія і Від Тіни Кросс, тому навчитися робити аквапарки зможуть і дівчата",
    image: forWhoIconFour,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Курс підійде для майстрів еротичного масажу, які вже голову зламали шукаючи якісну експертну інформацію",
    image: forWhoIconFive,
  },
  {
    title: "Хто має справу з “Я СОРОМЮСЬ”",
    description:
      "Ви навчитесь правильно збирати інформацію та розкривати жінок, знімаючи їх сором’язливість разом з одягом",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи та постійне оновлення уроків, без додаткових доплат",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "Якщо виникнуть питання, завжди є можливість поставити їх, як щодо курсу, так і особисті",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Компактна шпаргалка у будь-якому місці",
      description:
        "У курсі все компактно і по фактам — нічого зайвого, чисті знання прямо у твоєму телефоні",
    },
    {
      number: 5,
      title: "Лайфхаки з індустрії фільмів для дорослих",
      description:
        "Спікери екс порно зірки, від яких ти дізнаєшся як створити порно у реальному житті",
    },
    {
      number: 6,
      title: "Зможеш підкорити кожну",
      description:
        "Ми даємо 100% гарантії на те, що твої скіли зростуть до рівня сквіртолога, аби підкорити кожну ",
    },
  ],

};
export const KyniArr = {
  forWho: [
  {
    title: "Тому, хто хоче вивести свій секс на вищий рівень",
    description:
      "Тут ви знайдете покроковий гайд, як довести свою дівчину до оргазму/сквірту/ейфорії лише куніком",
    image: forWhoIconOne,
  },
  {
    title: "Хто шукає методи як трахати та заробляти",
    description:
      "Створення атмосфери, як у якісному порно фільмі",
    image: forWhoIcontwo,
  },
  {
    title: "Усім хто хоче стати кунімайстром абож кунімайcтринею",
    description:
      'В курсі ви знайдете уроки і від Сергія і Від Тіни Кросс, тому навчитися робити аквапарки зможуть і дівчата',
    image: forWhoIconThree,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Курс підійде для майстрів еротичного масажу, які вже голову зламали шукаючи якісну експертну інформацію",
    image: forWhoIconFour,
  },
  {
    title: "Для справжніх домінаторів",
    description:
      "Здавалося б — як? Та куні — це теж про домінацію! Ти навчишся підкорювати її сквірт",
    image: forWhoIconFive,
  },
  {
    title: "Мену, який не знає, що таке “скучно”",
    description:
      "Ти змусиш її забути слово “нудно” і кричати тільки “я кінчаю”",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "Якщо виникнуть питання, завжди є можливість поставити їх, як щодо курсу, так і особисті",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Зв'язок особисто з Сергієм 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 6,
      title: "Покрокове та наочне відео усіх технік",
      description:
        "Після курсу твоя шпаргалка буде з тобою за будь-яких умов та не залежно від розташування",
    },
  ],

};
export const AquaProArr = {
  forWho: [
  {
    title: "Тому, хто хоче підкоряти жіночі тіла",
    description:
      "Знання допоможуть тобі завоювати увагу кожної і ти забудеш, що таке відмова",
    image: forWhoIconOne,
  },
  {
    title: "Хто шукає методи як трахати та заробляти",
    description:
      "Інформація від першої особи: що і як відбувається у сфері 18+ і як туди потрапити",
    image: forWhoIcontwo,
  },
  {
    title: "Усім хто хоче усі знання в одному місці",
    description:
      'Усі види сексу — від А до Я. Тут місце трансформації твого життя',
    image: forWhoIconThree,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Курс підійде для майстрів еротичного масажу, які вже голову зламали шукаючи якісну експертну інфрмацію",
    image: forWhoIconFour,
  },
  {
    title: "Мену, який має справу з цнотливою",
    description:
      "Ти навчишся розкривати її квіточку на повну ще до початку масажу",
    image: forWhoIconFive,
  },
  {
    title: "Акулам, які хочуть секс у трьох",
    description:
      "Секс у трьох стане для тебе легкою задачею: тут усе від “як легко знайти третю” до “як вести тіндер”",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Канал випускників",
      description:
        "В тебе більше не буде недостатньо жіночої уваги. Після офлайн екзамену усі випускники курсу потрапляють в канал та отримують свою анкету акули сексу",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Зв'язок особисто з Сергієм 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків та особисті настанови від засновників",
      description:
        "Ти потрапиш в закрите кам’юніні без табу, та з вільним обговоренням тем 18+",
    },
    {
      number: 6,
      title: "Покрокове та наочне відео усіх технік",
      description:
        "Після курсу твоя шпаргалка буде з тобою за будь-яких умов та не залежно від розташування",
    },
  ],

};
export const dominantArr = {
  forWho: [
  {
    title: "Тому хто хоче домінувати і не лише в сексі",
    description:
      "Зробимо з твого життя цілодобову БДСМ сесію, бо домінація починається з голови",
    image: forWhoIconOne,
  },
  {
    title: "Домінанту чи доміні?",
    description:
      "Курс корисний не лише на чоловіків, а й для справжніх домін",
    image: forWhoIcontwo,
  },
  {
    title: "Тому, хто хоче вивести свій секс на вищий рівень",
    description:
    'Знання БДСМ піднімуть градус до рівня вулкану',
    image: forWhoIconThree,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Курс підійде для професійних домінантів: тут ви прокачаєте свої і отримаєте нові скіли до профільного спеціаліста ",
    image: forWhoIconFour,
  },
  {
    title: "Хто шукає методи як трахати та заробляти",
    description:
      "Навчись монетизувати своє хоббі: БДСМ — те за що готові платити",
    image: forWhoIconFive,
  },
  {
    title: "Хто хоче правильно втілювати свої дикі бажання в життя",
    description:
      "Випусти своїх демонів і вона буде благати тебе зв’язати її",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "Якщо виникнуть питання, завжди є можливість поставити їх, як щодо курсу, так і особисті",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Список топ девайсів для сесій",
      description:
        "Підкажемо, що обрати та як використати на повну",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 6,
      title: "Покрокове та наочне відео БДСМ технік",
      description:
        "Подивись і втілюй в життя: твої сесії стануть витвором еро мистецтва",
    },
  ],

};
export const pozyArr = {
  forWho: [
  {
    title: "Парі, яка шукає нових відчуттів",
    description:
      "Ви за адресою: тут ти навчишся трахатись по новому, навіть у вже звичних позах",
    image: forWhoIconOne,
  },
  {
    title: "Хто хоче трахатись, як профі",
    description:
      "Спікери екс порно зірки, які покажуть тобі, що таке просунутий рівень сексу",
    image: forWhoIcontwo,
  },
  {
    title: "Дівчині, яка хоче відчувати його оргазм",
    description:
      'Зроби феєрію його члену використавши вакуум. Уроки від Тіни Кросс вже чекають на тебе',
    image: forWhoIconThree,
  },
  {
    title: "Парам, які хочуть кінчати разом",
    description:
      "Лайфхаки витрахані і перетрахані — працює щоразу оргазмово",
    image: forWhoIconFour,
  },
  {
    title: "Тим хто хоче заглибитись у секс тему",
    description:
      "Тут ви навчитесь не просто трахатись, а кохатись, зливаючись тілами і душами",
    image: forWhoIconFive,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Прокачуй свої скіли та заробляй глибокими знаннями сексу",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Індивідуальний розбір ваших проблемних питань",
      description:
        "Якщо виникнуть питання, завжди є можливість поставити їх, як щодо курсу, так і особисті",
    },
    {
      number: 3,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 4,
      title: "Зв'язок особисто з засновниками 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 6,
      title: "Лайфхаки випробувані сотнями недоспаних ночей",
      description:
        "Ми знайшли формулу сквіртових поз — тепер ви кінчатимете по новому",
    },
  ],

};
export const analArr = {
  forWho: [
  {
    title: "Дівчині чи хлопцю? Не має значення!",
    description:
      "Анал — це те, що можна всім. А спікери геї на курсі — чимале тому підтвердження",
    image: forWhoIconOne,
  },
  {
    title: "Парі, яка хоче  тріо",
    description:
      "Усе покажемо та розкажемо: ви будете готові до будь-яких викликів.",
    image: forWhoIcontwo,
  },
  {
    title: "Кому “і хочеться, і колеться”",
    description:
      'Анал це найперше не про зад, а про твій мозок. Пора пропрацювати усі твої тригери',
    image: forWhoIconThree,
  },
  {
    title: "Різнобічній особистості, спраглій до знань",
    description:
      "Анальний оргазм — це те, чого ти не забудеш ніколи. Усе, про що ти думатимеш після “Чому я не робила цього раніше?!”",
    image: forWhoIconFour,
  },
  {
    title: "Тим хто хоче заглибитись у секс тему",
    description:
      "Тут ви навчитесь не просто трахатись у зад, а кохатися, розкриваючи нові грані вашого партнера",
    image: forWhoIconFive,
  },
  {
    title: "Тим хто хоче підвищити свою еро кваліфікацію",
    description:
      "Прокачуй свої скіли та заробляй глибокими знаннями сексу",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 3,
      title: "Зв'язок особисто з засновниками 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 4,
      title: "Лайфхаки для повного розслаблення ануса",
      description:
        "Перелік засобів, які розкриють вам увесь кайф аналу на 120%",
    },
    {
      number: 5,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 6,
      title: "Повний список девайсів",
      description:
        "Розкажемо та покажемо де взяти все, що тобі треба для навчання і після нього",
    },
  ],

};
export const KissArr = {
  forWho: [
  {
    title: "НЕЦІЛОВАНИМ",
    description:
      "Перший поцілунок ти запам’ятаєш більше ніж перший секс. Вивчай підкорювати не помідори, а серця",
    image: forWhoIconOne,
  },
  {
    title: "Акулам, які хочуть тріо",
    description:
      "Бонусний урок від Тіни Крос розкриє усі фішки найскладнішої техніки поцілунку в трьох",
    image: forWhoIcontwo,
  },
  {
    title: "Тим, хто хоче нових відчуттів",
    description:
      'На курсі ви дізнаєтесь про техніки з девайсами та смаками, від яких вам зірве дах',
    image: forWhoIconThree,
  },
  {
    title: "Хто шукає відповідь “а що не так”",
    description:
      "Розглянемо і виправимо усі помилки, які ви робите, зливаючись губами",
    image: forWhoIconFour,
  },
  {
    title: "Творцям мистецтва",
    description:
      "Вчіться у профі цілуватись як у фільмах, і щоб кожен поцілунок приводив до хепі енду",
    image: forWhoIconFive,
  },
  {
    title: "Справжнім майстрам",
    description:
      "Підвищуйте свою кваліфікацію до рівня магістра поцілунків",
    image: forWhoIconSix,
  },
  ],

  benefiteCourseArr: [
    {
      number: 1,
      title: "Доступ до уроків назавджи",
      description:
        "Купивши курс, ви отримуєте доступ до знань до кінця життя і можете переглядати уроки в будь-якому місці та не залежно від часу.",
    },
    {
      number: 2,
      title: "Доступ до закритого телеграм каналу курсу",
      description:
        "В телеграм каналі ви зможете знайти однодумців та обговорювати теми які цікавлять, а також переглядати уроки просто у месенджері",
    },
    {
      number: 3,
      title: "Зв'язок особисто з засновниками 24/7",
      description:
        "У ситуації SOS ви можете написати особисто засновникам та отримати пораду по вашому індивідуальному питанню",
    },
    {
      number: 4,
      title: "Постійне оновлення уроків, без додаткових доплат",
      description:
        "Оплативши курс один раз ви автоматично отримаєте доступ і до уроків які додаються на курс після, без зростання ціни.",
    },
    {
      number: 5,
      title: "Повний список девайсів",
      description:
        "Розкажемо та покажемо де взяти все, що тобі треба для навчання і після нього",
    },
    {
      number: 6,
      title: "Лайфхаки випробувані сотнями недоспаних ночей",
      description:
        "Ми знайшли формулу ідеальних поцілунків — тепер ви цілуватимете по новому",
    },
  ],

};

export const manCourses = [
  {
    title: "Потенціал Члена",
    describtion:
      "Потенціал - це те, що можна і треба розвивати. І ні - немає ніяких обмежень! Твій член стоятиме усю ніч.  На курсі ти навчишся трахати не гірше порно акторів і отримувати оргазм, який просто розірве твій мозок. Чітко виконуючи усі завдання курсу вже через місяць ти забудеш, що таке швидкий фініш чи проблеми з падіннями. Розкриваємо усі лайфхаки та методи, щоб твій друг був на поготові завджи і всюди, і фінішував як вперше до глибокої старості.",
    price: "100$",
    imageSrc: coursePhotoOneMan,
    url: "/man-side/potencial-chlena",
    willLernArr: ["Як навчитись підконтрольно кінчати", "Методи позитивної мастурбації", "Як збільшити час статевого акту", "Як регулювати чутливість члена", "Лайфхаки для ЖМЖ, МЖЖЖ…", "Як фінішувати з дівчиною одночасно",],
    urlCover: coverPotencial,
  },
  {
    title: "Йоні Майстер",
    describtion:
      "Твоя дівчина нарешті дізнається, що таке сквірт! Так — це не міф: тепер відчути це зможе кожна. Усі уроки записані на дівчині, яка ніколи не сквіртувала — її живі емоції та усі техніки крупним планом дають можливість зануритись глибоко у тему та дізнатись усі тонкощі того самого “неуловимого” . Ми навчимо тебе — як творити оргазм найперше в її голові. Але одразу попередження: твої нові вміння стануть темою для обговорення в колі її подружок. Готовий? GO.",
    price: "250$",
    imageSrc: coursePhotoTwoMan,
    url: "/man-side/yoni-master",
    willLernArr: ["Як творити сквірт руками і не тільки", "Про хитрощі, які розкриють навіть найзажатішу квітку", "Які пози найзручніші і найефективніші для сквірту", "Як точно дізнатись чи сподобалось їй", "Техніки, з якими вона сама потягнеться до твого члена", "Відповідь — чому в тебе не виходить аквапарк",],
    urlCover: coverAquaEx,
  },
  {
    title: "Куні до Сквірту",
    describtion:
      "ДОСИТЬ ЯЛОЗИТИ ЯЗИКОМ ЯК ВЕСЛОМ, вже сьогодні вона буде кінчати і сквіртувати від твого КУНІКА. Гарна новина для бішечок та лесбійок: тут ви знайдете топові уроки які підійдуть як для чоловіків, так і для дівчат! Після навчання ти більше не почуєш “сквірт — це щось на фантастичному”. Починай ламати ці міфи — твій язик здатний на все.",
    price: "200$",
    imageSrc: coursePhotoSixMan,
    url: "/man-side/kyni-do-skvirta",
    willLernArr: ["10+ форматів ідеального відлизу", "Як відключити її голову і віднести у світ насолоди", "Як відлизувати та домінувати над нею", "Як прибрати її соромʼязливість", "Як завести її ще у переписці", "Формати, які від тебе вона відчує вперше",],
    urlCover: coverKyni,
  },
  {
    title: "Аквапарк Pro Max VIP",
    describtion:
      "ТРАХАЙ ТА ЗАРОБЛЯЙ Тут лише профі! Знання з курсу допоможуть тобі не лише задовільняти свою дівчину на найвищому рівні, а навіть потрапити в індустрію 18+. Якщо ти готовий до того, що твої руки, член та пальці будуть пікантною темою для розмов у колі жінок - тоді я чекамо тебе в банді.",
    price: "385$",
    imageSrc: coursePhotoFourMan,
    url: "/man-side/aquapark-pro-max-vip",
    willLernArr: ["Як спілкуватись професійно з її тілом та розумом", "Як стати масажистом йоні , твоя нова професія мрії", "Як знайти дівчину для сексу ЖМЖ", "Про сквірт руками та членом", "Усі фішки аналу, куні, бдсм та поз — інформація зі всіх курсів в одному місці", "Знатимеш про жінок більше ніж вони самі",],
    urlCover: coverAquaPro,
  },
  {
    title: "Домінант Pro Max",
    describtion:
      "Курс який відриє тобі БДСМ світ. Домінація — це те, що виведе твій секс та життя на топ рівень. Тут ти побачиш контент, який заслуговує на перше місце у топі PORN HUB, тому запасайся серветками і вивчай.",
    price: "130$",
    imageSrc: coursePhotoFiveMan,
    url: "/man-side/dominant",
    willLernArr: ["Як придушити її, щоб їй хотілось ще і ще…", "Як вона буде благати стати на коліна перед тобою", "Історію  БДСМ — у чому філософія та які ідеали", "Як втілити її фантазію насилля", "Усе про бандажування дівчини в домашніх умовах", "Про мистецтво порки: як і чим відшлеїти її по повній",],
    urlCover: coverDominant,
  },
  {
    title: "Пози 18+",
    describtion: "Такого формату сексу в тебе ще не було! Пози, які удосконалені та протестовані десятками пар. Такій деталізації позаздрить навіть Камасутра, адже цей курс — інструкція оновлення вашого інтиму до версії РRO. Навіть звичні пози ми перетворимо на витвори оргазмового мистецтва. Попереджаємо одразу — сусіди вас зненавидять і їх нічого не врятує від перевищення рівня децибелів.",
    price: "130$",
    imageSrc: coursePhotoSevenMan,
    url: "/man-side/pozy-18",
    willLernArr: ["Про нові формати вже звичних поз", "Як вакуум впливає на ваш секс: топ техніки від Тіни Кросс", "Як професійно змінювати пози не виймаючи член", "Лайфхаки які доведуть до піку вас обох", "Як зробити масаж члена мʼязами вагіни", "Про “тверк на члені” для нового рівня ваших відчуттів покроково",],
    urlCover: coverPozy,
  },
  {
    title: "Анал до сквірта",
    describtion:
      "Відкрий новий вхід у світ насолоди. Чому цей курс у розділі для пар? Бо сквіртовий анал вимагає знань від двох. Тому ми зібрали найефективнішу підготовку до АНАЛЬНОГО СЕКСУ для дівчини і хлопця. Дівчата, готуй не лише свою жопу, а й мозок — тут ми пропрацює усі тригери, розтиснемо булочки та душу. А ви пацики готуйте член та мізки — тут потрібні вони обидва. READY?",
    price: "130$",
    imageSrc: coursePhotoThreeMan,
    url: "/man-side/anal-do-skvirta",
    willLernArr: ["Чому і як потрібно готуватися до АС вам обом", "Як пропрацювати психологічні моменти перед аналом", "Фішки та лайфхаки від порно акторів, щоб оргазмувати по новому", "Як помножити її кайф: подвійне проникнення", "Мінет аналом — прокачайся до рівня профі", "Правила та способи використання допоміжних девайсів",],
    urlCover: coverAnal,
  },
  {
    title: "Цілуй",
    describtion:
      "Поцілунок — це те, з чого усе починається. І в кожного є та сама крінж історія поцілунку, яку твій мозок намагається забути. “Та що там його цілуватися” - фатальна помилка. Поцілунок — інтимніше навіть сексу. Саме він може або подарувати тобі найкращі стосунки твого життя або зруйнувати все ще на самому початку. Час вивести свої цілувальні здібрості на рівень ПРОФІ. Починай вивчати вже зараз",
      price: "50$",
      imageSrc: coursePhotoEightMan,
    url: "/man-side/kiss",
    willLernArr: ["Як почати цілуватись так, щоб продовжувати вечір вже у ліжку", "Про важливість поцілунку: це те з чого усе і починається", "Як точно не треба: Вивчай, щоб не стати героєм історії про “поцілунок шарпея”", "Усе про поцілунок в трьох, для справжніх поціновувачів", "Поцілунки з льодом та смаком -  те, від чого знесе дах обом", "Які моменти ти пропускаєш, що ламає увесь кайф поцілунку",],
    urlCover: coverKiss,
  },
];

