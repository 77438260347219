import graduateImage from '../assets/images/graduate.webp'
import graduateImage1 from '../assets/images/graduate1.webp'
import graduateImage2 from '../assets/images/graduate2.webp'
import graduateImage3 from '../assets/images/graduate3.webp'
import graduateImage4 from '../assets/images/graduate4.webp'
import graduateImage5 from '../assets/images/graduate5.webp'
import graduateImage6 from '../assets/images/graduate6.webp'
import graduateImage7 from '../assets/images/graduate7.webp'
import graduateImage8 from '../assets/images/graduate8.webp'
import graduateImage9 from '../assets/images/graduate9.webp'

export const graduateArr = [
   {
    data: 'Роман, 29, Варшава',
    src: graduateImage,
    href: "https://t.me/soulful_rising"
   },
   {
    data: 'Сергій, 31, Одеса',
    src: graduateImage1,
    href: "https://t.me/Sergii2310"
   },
   {
    data: 'Віктор, 30, Одеса',
    src: graduateImage2,
    href: "https://t.me/Agronom43"
   },
   {
    data: 'Михайло, 37, Київ',
    src: graduateImage3,
    href: "https://t.me/mihail7058"
   },
   {
    data: 'Віктор, 23, Київ',
    src: graduateImage4,
    href: "https://t.me/Victor_Diktor"
   },
   {
    data: 'Сергій, 29, Одеса',
    src: graduateImage5,
    href: "https://t.me/sergeymadison"
   },
   {
    data: 'Юрій, 29, Львів',
    src: graduateImage6,
    href: "https://t.me/yuriy_martiuk"
   },
   {
    data: 'Сергій, 25, Херсон',
    src: graduateImage7,
    href: "https://t.me/Sserjiio"
   },
   {
    data: 'Влад, 28, Київ',
    src: graduateImage8,
    href: "https://t.me/Kakao_and_you"
   },
   {
    data: 'Альберт, 25, Київ',
    src: graduateImage9,
    href: "https://t.me/russian_rat_killer"
   },
]