import { Link, useLocation } from "react-router-dom";
import "./FooterContacts.css";
import instIcon from "../../assets/icons/insticon.png";
import tgIcon from "../../assets/icons/tgicon.png";
import ttstIcon from "../../assets/icons/tticon.png";
import instIconWoman from "../../assets/icons/instwomanicon.png";
import tgIconWoman from "../../assets/icons/tgwomanicon.png";
import ttstIconWoman from "../../assets/icons/ttWomenIcon.png";
import FooterContactsButton from "./FooterContactsButton/FooterContactsButton";
import { useDispatch } from "react-redux";
import { setIsActive } from "../../redux/ModalSlice/ModalSlice";

function FooterContacts() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isCourse = /side\/.+/.test(location.pathname);
  const isMainPage = location.pathname === "/";
  const hideFooterContacts = isMainPage;

  const handleModalOpen = () => {
    dispatch(setIsActive());
  };

  return (
    <section
      style={{ display: hideFooterContacts ? "none" : "flex" }}
      className="footer__contacts"
    >
      <div className="mobile-block"></div>
      <div className="footer__contacts-wrapper">
        <div className="footer__contacts-left">
          <div className="footer__contacts-texts">
            <h2 className="footer__contacts-name text-title-two man-color">
              Leon13
            </h2>
            <Link
              to={"/all-courses"}
              style={{ color: "white" }}
              className="text-title-two"
            >
              Всі курси
            </Link>
            <Link to={"man-side"} className="footer__contacts-nav text-title-two">
              Чоловічій простір
            </Link>
          </div>
          <div className="footer__contacts-icons">
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.instagram.com/leonhub_13/"
              className="footer__contacts-link"
            >
              <img
                src={instIcon}
                alt="посилання на інстаграм Leon13"
                className="footer__contacts-icon"
              />
            </a>
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://t.me/+TkaZmEfQeEBiYmNi"
              className="footer__contacts-link"
            >
              <img
                src={tgIcon}
                alt="посилання на телеграм Leon13"
                className="footer__contacts-icon"
              />
            </a>
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.tiktok.com/@inst.leonhub_13"
              className="footer__contacts-link"
            >
              <img
                src={ttstIcon}
                alt="посилання на тікток Leon13"
                className="footer__contacts-icon"
              />
            </a>
          </div>
        </div>
        <div className="footer-contacts-button-wrapper">
          <FooterContactsButton />
          <div className="concave__wrapper">
            <div className="concave">
              <div className="rightconcave"></div>
            </div>
          </div>
          <div className="concave__wrapper-two">
            <div className="concave-two">
              <div className="rightconcave-two"></div>
            </div>
          </div>
          <button
            onClick={handleModalOpen}
            className="footer__contacts-button text-title-two"
          >
            <p className="footer__contacts-button-text">
              {isCourse ? "Придбати курс" : "Консультація"}
            </p>
          </button>
        </div>
        <div className="footer__contacts-right">
          {" "}
          <div className="footer__contacts-texts">
            <h2 className="footer__contacts-name text-title-two w woman-color">
              TinaKross
            </h2>
            <Link
              to={"/all-courses"}
              style={{ color: "white" }}
              className="text-title-two w"
            >
              Всі курси
            </Link>
            <Link to={"woman-side"} className="footer__contacts-nav w text-title-two">
              Жіночий простір{" "}
            </Link>
          </div>
          <div className="footer__contacts-icons">
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.instagram.com/tinakross001/"
              className="footer__contacts-link"
            >
              <img
                src={instIconWoman}
                alt="посилання на інстаграм Tina Kross"
                className="footer__contacts-icon"
              />
            </a>
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://t.me/+HxiWGYJdoRQ2YTYy"
              className="footer__contacts-link"
            >
              <img
                src={tgIconWoman}
                alt="посилання на телеграм Tina Kross"
                className="footer__contacts-icon"
              />
            </a>
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.tiktok.com/@tinakross_pro_vse"
              className="footer__contacts-link"
            >
              <img
                src={ttstIconWoman}
                alt="посилання на тікток Tina Kross"
                className="footer__contacts-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FooterContacts;
